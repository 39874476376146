.footer {
    padding: 50px 0;
    border-top: 5px solid #1096b0;
    background-image: url("../../src//assets//footer-img.jpeg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    z-index: 1;
}

.footer::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000053;
    opacity: 0.5;
    z-index: -1;
}

.footer-one {
    padding: 20px 0;
}

.footer-one p {
    font-size: 18px;
    color: white;
    font-family: "Inter", sans-serif;
}

.footer-two {
    display: flex;
    justify-content: start;
    align-items: center;
    flex-wrap: wrap;
    gap: 15px 25px;
    padding: 20px 0px;
}

.footer-two a {
    color: white;
    text-decoration: none;
    font-size: 18px;
    font-family: "Inter", sans-serif;
}

.footer-three {
    padding: 20px 0;
}

.footer-four {
    padding: 20px 0;
}

.footer-four p {
    font-size: 18px;
    color: white;
    font-family: "Inter", sans-serif;
}

.footer-three .social-icons a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    background: white;
    color: black;
    text-decoration: none;
    font-size: 24px;
    border-radius: 10px;
}

.footer-three .social-icons {
    display: flex;
    align-items: center;
    gap: 20px;
}

@media screen and (max-width: 576px) {
    .footer {
        padding: 10px 0;
        border-top: 0px solid #1096b0;
    }

    .footer-one p,
    .footer-two a,
    .footer-four p {
        font-size: 14px !important;
    }

    .footer-two a {
        width: 100%;
    }

    .footer-four p {
        margin-bottom: 0px;
    }
}