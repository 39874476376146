.contact-us2 {
    padding: 50px 0;
}

.contact-us2 .right-section .right-section-heading p {
    font-size: 48px;
    color: white;
    font-family: "Exo 2", sans-serif;
}

.contact-us2 .right-section {
    padding-left: 90px;
}

.contact-us2 .right-section .right-section-info p {
    font-size: 24px;
    color: white;
    font-family: "Inter", sans-serif;
    text-align: justify;
}

.contact-us2 .left-section-one {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 25px;
}

.contact-us2 .left-section-one label {
    color: white;
    font-size: 16px;
    font-family: "Inter", sans-serif;
}

.contact-us2 .left-section-two .header p {
    color: white;
    font-size: 16px;
    font-family: "Inter", sans-serif;
}

.contact-us2 .left-section-two-inner .fields label {
    color: white;
    font-size: 16px;
    font-family: "Inter", sans-serif;
}

.contact-us2 .left-section-two {
    margin-top: 20px;
    margin-bottom: 5px;
}


.contact-us2 .left-section-three button {
    width: 144px;
    height: 40px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    font-size: 16px;
    font-family: "Inter", sans-serif;
}

.contact-us2 .left-section-three {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.contact-us2 .left-section-three p {
    margin-bottom: 0px;
    color: #fff;
    width: 50%;
    font-family: "Inter", sans-serif;
}

.contact-us2 .left-section-three a {
    font-weight: 500;
    color: #fff;
    text-decoration: none;
    font-family: "Inter", sans-serif;
}

.contact-us2 .left-section form input {
    background-color: #ffffff80 !important;
    border-radius: 8px;
    color: #ffffff;
    border: none !important;
    outline: none !important;
}

.contact-us2 .left-section form textarea {
    background-color: #ffffff80 !important;
    border-radius: 8px;
    color: #ffffff;
    border: none !important;
    outline: none !important;
}
.contact-us2 .left-section form select {
    color: #ffffff;
}

.form-block label {
    font-size: 16px;
    font-family: "Inter", sans-serif;
    color: #fff;
    margin-bottom: 4px;
}

.form-heading-title {
    font-size: 17px;
    font-weight: 600;
    font-family: "Inter", sans-serif;
    color: #fff;
}

.contact-us2 .right-section .right-section-info ul li {
    color: white;
    font-size: 24px;
    font-family: "Inter", sans-serif;
    text-align: justify;
}

.contact-us2 .right-section .right-section-info ul {
    list-style: none;
    padding: 0 !important;
}


@media screen and (max-width:1400px) {
    .contact-us2 .right-section .right-section-heading p {
        font-size: 36px;
        font-weight: 700;
    }

    .contact-us2 .right-section .right-section-info p,
    .contact-us2 .right-section .right-section-info ul li {
        font-size: 18px;
    }

    .contact-us2 .left-section-three {
        flex-direction: column-reverse;
        gap: 10px;
    }

    .contact-us2 .left-section-three p {
        margin-bottom: 20px !important;
        width: 100% !important;
        font-size: 12px !important;
    }
}


@media screen and (max-width:576px) {
    .contact-us2 .right-section .right-section-heading p {
        font-size: 20px !important;
    }

    .contact-us2 .left-section-three {
        flex-direction: column-reverse;
        gap: 10px;
    }

    .contact-us2 .left-section-three p {
        margin-bottom: 20px !important;
        width: 100% !important;
        font-size: 12px !important;
    }

    .contact-us2 .right-section {
        padding-left: 0px;
    }

    .contact-us2 .right-section .right-section-info ul li {
        font-size: 18px;
    }

    .contact-us2 {
        padding: 30px 0;
    }

}

.error-message{
    color: red;
}
