.partner-search-banner .img-box1 img {
    width: 100%;
    /* max-width: 100%; */
    object-fit: cover;
    height: 320px;
}

.partner-search-banner .img-box1 {
    position: relative;
}

.partner-search-banner .img-box1::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000053;
    opacity: 0.22;
}

.partner-search-banner .img-box2 {
    position: relative;
    background-image: url('../../src//assets/partner-search-banner2.jpeg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 100px 0;
    z-index: 1;
}

.partner-search-banner .img-box2::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: #000053;
    opacity: 0.22;
    top: 0;
    left: 0;
    z-index: -1;
}

.partner-search-banner .img-box2 .img-box2-inner .heading p {
    font-size: 72px;
    color: white;
    font-family: "Exo 2", sans-serif;
    font-weight: 600;
}

.partner-search-banner .img-box2 .img-box2-inner .content p {
    font-size: 24px;
    color: white;
    font-family: "Inter", sans-serif;
}

.partner-search-banner .img-box2 .img-box2-inner .button a {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    width: 160px;
    height: 56px;
    border: none;
    border-radius: 7px;
    font-family: "Inter", sans-serif;
    background-color: white;
    color: black;
    text-decoration: none;
    font-weight: 500;
}

.partner-search-section2 {
    padding: 40px 0;
}

.partner-search-section2 .heading {
    text-align: center;
    padding: 10px 0;
}

.partner-search-section2 .heading p {
    font-size: 48px;
    color: white;
    font-family: "Exo 2", sans-serif;
}


.partner-search-section2 .partner-search-section2-card .heading p {
    font-size: 32px;
    color: white;
    font-family: "Exo 2", sans-serif;
}

.partner-search-section2 .partner-search-section2-card .content p {
    font-size: 24px;
    color: white;
    font-family: "Inter", sans-serif;
    text-align: justify;
}

.partner-search-section2 .partner-search-section2-card .img-box img {
    width: 100%;
    object-fit: cover;
    height: 264px;
}

.partner-search-section3 .img-box img {
    width: 100%;
    object-fit: cover;
}

.partner-search-section3 .right-section .heading p {
    font-size: 48px;
    color: white;
    font-family: "Exo 2", sans-serif;
}

.partner-search-section3 .right-section {
    padding: 20px 60px;
}

.partner-search-section3 .right-section .content p {
    font-size: 24px;
    color: white;
    font-family: "Inter", sans-serif;
}

.partner-search-section3 .right-section .ordered-list ol li {
    font-size: 24px;
    color: white;
    font-family: "Inter", sans-serif;
    text-align: justify;
}

.partner-search-section4 {
    padding: 55px 0;
}

.partner-search-section4 .heading {
    text-align: center;
}

.partner-search-section4 .heading p {
    font-size: 48px;
    color: white;
    font-family: "Exo 2", sans-serif;
}

.partner-search-section4 .content p {
    font-size: 24px;
    color: white;
    font-family: "Inter", sans-serif;
    position: relative;
    text-align: justify;
}

.partner-search-section4 .content p::before {
    top: 0;
    left: -30px;
    position: absolute;
    content: '->';
    color: white;
}

.partner-search-section5 {
    padding: 20px 0;
}

.partner-search-section5 .partner-search-section5-heading p {
    font-size: 48px;
    color: white;
    font-family: "Exo 2", sans-serif;
}

.partner-search-section5 .partner-search-section5-card .heading {
    height: 100%;
    padding: 20px 0px 0px;
}

.partner-search-section5 .partner-search-section5-card .heading p {
    font-size: 32px;
    color: white;
    font-family: "Exo 2", sans-serif;
}

.partner-search-section5 .partner-search-section5-card .content p {
    font-size: 24px;
    color: white;
    font-family: "Inter", sans-serif;
}

.partner-search-section5 .partner-search-section5-card .img-box {
    border-radius: 10px;
    overflow: hidden;
}

.partner-search-section5 .partner-search-section5-card .img-box img {
    width: 100%;
    object-fit: cover;
    height: 194px;
}


@media screen and (max-width:1400px) {
    .partner-search-banner .img-box2 .img-box2-inner .heading p {
        font-size: 48px;
        line-height: 56px;
    }

    .partner-search-section2 .partner-search-section2-card .heading p,
    .partner-search-section5 .partner-search-section5-card .heading p {
        font-size: 24px;
    }

    .partner-search-section2 .heading p,
    .partner-search-section3 .right-section .heading p,
    .partner-search-section5 .partner-search-section5-heading p {
        font-size: 36px;
    }

    .partner-search-banner .img-box2 .img-box2-inner .content p,
    .partner-search-section2 .partner-search-section2-card .content p,
    .partner-search-section3 .right-section .content p,
    .partner-search-section3 .right-section .ordered-list ol li,
    .partner-search-section4 .content p,
    .partner-search-section5 .partner-search-section5-card .content p {
        font-size: 18px;
    }
}

@media screen and (max-width:576px) {
    .partner-search-section3 .right-section .ordered-list ol li {
        font-size: 18px;
    }

    .partner-search-section3 .right-section {
        padding: 20px 15px;
    }

    .partner-search-section5 .partner-search-section5-card .heading {
        height: 100%;
    }

    .partner-search-section4 {
        padding: 35px 0;
    }
}