.investor-search .section1 {
    background-image: url(../../src/assets/banner7.jpeg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    z-index: 1;
    padding: 180px 0;
}

.investor-search .section1::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    background-color: #000053;
    opacity: 0.5;
    z-index: -1;
}

.investor-search .section1 .heading h1 {
    color: white;
    font-size: 72px;
    font-family: "Exo 2", sans-serif;
}

.investor-search .section1 .content {
    padding: 20px 0;
}

.investor-search .section1 .content p {
    font-size: 24px;
    color: white;
    font-family: "Inter", sans-serif;
}

.investor-search .section1 .button a {
    width: 212px;
    height: 56px;
    display: flex;
    border: none;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-family: "Inter", sans-serif;
    border-radius: 7px;
    background-color: #fff;
    color: #000;
    text-decoration: none;
}

.investor-search .section2 .card1 {
    padding: 20px 100px;
}

.investor-search .section2 .card2 {
    padding: 20px 100px;
}

.investor-search .section2 .card1 .heading {
    text-align: center;
}

.investor-search .section2 .card2 .heading {
    text-align: center;
}

.investor-search .section2 .card1 .heading h2 {
    font-size: 48px;
    color: white;
    font-family: "Exo 2", sans-serif;
}

.investor-search .section2 .card1 .content p {
    color: white;
    font-size: 24px;
    font-family: "Inter", sans-serif;
    position: relative;
}

.investor-search .section2 .card1 .content p::before {
    position: absolute;
    top: 0;
    left: -27px;
    content: "->";
}

.investor-search .section2 .card1 .content h3 {
    color: white;
    font-size: 24px;
    font-family: "Inter", sans-serif;
}

.investor-search .section2 .card2 .heading h2 {
    font-size: 48px;
    color: white;
    font-family: "Exo 2", sans-serif;
}

.investor-search .section2 .card2 .content p {
    color: white;
    font-size: 24px;
    font-family: "Inter", sans-serif;
    position: relative;
}

.investor-search .section2 .img-box img {
    width: 100%;
    object-fit: cover;
    height: 535px;
}

.investor-search .section2 .card2-main {
    height: 100%;
    display: flex;
    align-items: center;
}


@media screen and (max-width:1400px) {
    .investor-search .section1 .heading h1 {
        font-size: 52px;
    }

    .investor-search .section1 .content p {
        font-size: 20px;
    }

    .investor-search .section2 .card1,
    .investor-search .section2 .card2 {
        padding: 20px 40px;
    }

    .investor-search .section2 .card1 .heading h2,
    .investor-search .section2 .card2 .heading h2 {
        font-size: 36px;
    }

    .investor-search .section2 .card1 .content p,
    .investor-search .section2 .card2 .content p {
        font-size: 18px;
    }
}

@media screen and (max-width:576px) {
    .investor-search .section1 .heading h1 {
        font-size: 42px;
    }

    .investor-search .section1 .content p {
        font-size: 18px;
    }

    .investor-search .section2 .card1 {
        padding: 20px 40px;
    }

    .investor-search .section2 .card1 .heading h2 {
        font-size: 30px;
    }

    .investor-search .section2 .card1 .heading h2,
    .investor-search .section2 .card2 .heading h2 {
        font-size: 30px;
    }
}