.web-mobile-apps .section1 {
    /* padding: 100px 0; */
    background-color: #2f1f66;
}

.web-mobile-apps .section1 .card-top {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.web-mobile-apps .section1 .img-box {
    text-align: center;
}

.web-mobile-apps .section1 .img-box img {
    width: 100%;
    object-fit: cover;
}

.web-mobile-apps .section1 .vt-card .heading h1 {
    color: white;
    font-size: 72px;
    font-family: "Exo 2", sans-serif;
}

.web-mobile-apps .section1 .vt-card .content {
    padding: 20px 0;
}

.web-mobile-apps .section1 .vt-card .content p {
    color: white;
    font-size: 24px;
    font-family: "Inter", sans-serif;
}

.web-mobile-apps .section1 .vt-card .button a {
    border: none;
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 180px;
    height: 60px;
    font-size: 20px;
    font-weight: 500;
    font-family: "Inter", sans-serif;
    background: white;
    color: black;
    text-decoration: none;
}

.web-mobile-apps .section2 {
    padding: 50px 0;
}

.web-mobile-apps .section2 .heading h2 {
    color: white;
    font-size: 48px;
    font-family: "Exo 2", sans-serif;
}

.web-mobile-apps .section2 .content p {
    padding: 20px 0;
}

.web-mobile-apps .section2 .content p {
    color: white;
    font-size: 24px;
    font-family: "Inter", sans-serif;
}

.web-mobile-apps .section4 {
    padding: 20px 0;
}

.web-mobile-apps .section4 .heading h2 {
    color: white;
    font-size: 48px;
    font-family: "Exo 2", sans-serif;
}

.web-mobile-apps .section4 .content p {
    padding: 20px 0;
}

.web-mobile-apps .section4 .content p {
    color: white;
    font-size: 24px;
    font-family: "Inter", sans-serif;
}

.web-mobile-apps .section4 .img-box {
    border-radius: 10px;
    overflow: hidden;
}

.web-mobile-apps .section4 .img-box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.web-mobile-apps .section3 {
    padding-bottom: 100px;
}

.web-mobile-apps .section3 .main-heading h2 {
    font-size: 48px;
    color: white;
    font-family: "Exo 2", sans-serif;
}

.web-mobile-apps .section3 .cards .headings {
    text-align: center;
    height: 90px;
}

.web-mobile-apps .section3 .cards .headings .heading h2 {
    font-size: 18px;
    font-family: "Poppins", sans-serif;
    color: #1f4ec4;
}

.web-mobile-apps .section3 .cards .headings .sub-heading h2 {
    font-size: 14px;
    font-family: "Poppins", sans-serif;
    color: #7da2ff;
}

.outer-box .cards {
    position: relative;
}

.outer-box .cards::before {
    position: absolute;
    content: "";
    top: 60px;
    left: 50%;
    width: 18px;
    height: 18px;
    background: #1f4ec4;
    border-radius: 50%;
    transform: translateX(-50%);
}

.outer-box .cards::after {
    position: absolute;
    content: "";
    top: 68px;
    left: 0;
    width: calc(100% + 22px);
    height: 2px;
    background: #fff;
    z-index: -1;
}

.web-mobile-apps .section3 .outer-box {
    display: flex;
    overflow-x: scroll;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    padding: 20px 0px;
    /* margin: 0 10px; */
    position: relative;
}


.web-mobile-apps .section3 .cards .vt-card {
    background-color: #fff;
    padding: 20px;
    width: 250px;
    margin: 15px;
    border-radius: 8px;
    position: relative;
}

.cards .vt-card::before {
    position: absolute;
    content: "";
    top: -8px;
    left: 50%;
    width: 20px;
    height: 20px;
    background: white;
    border-radius: 3px;
    transform: translateX(-50%) rotate(45deg);
}

.web-mobile-apps .section3 .cards .vt-card .icon {
    margin-bottom: 15px;
    width: 100%;
    height: 100px;
}

.web-mobile-apps .section3 .cards .vt-card .icon img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.web-mobile-apps .section3 .cards .vt-card .vt-heading h2 {
    font-size: 18px;
    font-family: "Poppins", sans-serif;
    color: #1f4ec4;
}

.vt-card .content {
    min-height: 140px;
}

.web-mobile-apps .section3 .cards .vt-card .content p {
    font-size: 15px;
    font-weight: 300;
    font-family: "Poppins", sans-serif;
    color: #000000;
}

.web-mobile-apps .section3 .cards .vt-card .content p:last-child {
    margin-bottom: 0px;
}

@media screen and (max-width:1400px) {
    .web-mobile-apps .section1 .vt-card .heading h1 {
        font-size: 52px;
    }

    .web-mobile-apps .section1 .vt-card .content p {
        font-size: 20px;
    }

    .web-mobile-apps .section2 .heading h2,
    .web-mobile-apps .section3 .main-heading h2,
    .web-mobile-apps .section4 .heading h2 {
        font-size: 36px;
    }

    .web-mobile-apps .section2 .content p,
    .web-mobile-apps .section4 .content p {
        font-size: 18px;
    }
}

@media screen and (max-width:576px) {
    .web-mobile-apps .section1 {
        padding: 40px 0px;
    }

    .web-mobile-apps .section1 .vt-card .heading h1 {
        font-size: 42px;
    }

    .web-mobile-apps .section1 .vt-card .button a {
        width: 100%;
    }

    .web-mobile-apps .section2 .heading h2,
    .web-mobile-apps .section3 .main-heading h2,
    .web-mobile-apps .section4 .heading h2 {
        font-size: 30px;
    }

    .web-mobile-apps .section1 .vt-card .content p {
        font-size: 18px;
    }

    .web-mobile-apps .section3 .cards .vt-card .content p {
        font-size: 15px !important;
    }
}