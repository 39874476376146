/* src/ScrollToTop.css */
.scroll-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #fff;
    /* Primary button color */
    color: #000;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    font-size: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    transition: opacity 0.3s ease-in-out;
    opacity: 0;
    visibility: hidden;
    z-index: 999;
}

.scroll-to-top.visible {
    opacity: 0.8;
    visibility: visible;
}

.scroll-to-top:hover {
    opacity: 1;

}