.complany-logo img {
    width: 100%;
    height: 100%;
    max-width: 574px;
    max-height: 160px;
    object-fit: cover;
}

.complany-logo {
    margin: auto !important;
}


.bg-body-tertiary {

    background-color: #ebf4fe !important;
}

div#offcanvasNavbar {
    background: #ebf4fe !important;

}

button.navbar-toggler {
    box-shadow: none !important;
    border: 0 !important;
}

.navbar-nav .nav-link.active {
    color: #3b404e;
    /* Active link color */
    font-weight: bold;
    /* Optional: make the active link bold */
    background-color: rgba(0, 123, 255, 0.1);
    /* Optional: background color for the active link */
    border-radius: 4px;
    /* Optional: round the corners of the active link */
}

/* Hover state for links */
.navbar-nav .nav-link:hover {
    color: #3b404e;
    font-weight: 500;
}

@media screen and (max-width:1400px) {
    .complany-logo img {
        max-width: 320px;
        max-height: 90px;
    }
}