.aiSearch-main .banner-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* padding: 10px 255px; */
    margin: auto;
    width: 50vw;
    text-align: center;
    height: 844px;
    color: white;
}

.aiSearch-main .banner {
    background-image: url(../../src/assets/aisearch.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 844px;
    position: relative;
    z-index: 1;
}

.aiSearch-main .banner::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000053;
    opacity: 0.22;
    z-index: -1;
}

.aiSearch-main .banner-inner h1 {
    font-size: 72px;
}

.aiSearch-main .banner-inner p {
    font-size: 24px;
}

.aiSearch-main a.learn-more {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 160px;
    height: 56px;
    font-size: 20px;
    border: none;
    background: white;
    border-radius: 8px;
    font-weight: 500;
    text-decoration: none;
    color: #000;
}

.aiSearch-main .section2 {
    margin-top: 40px;
    margin-bottom: 40px;
}

.aiSearch-main .section2-inner {
    color: white;
}

.aiSearch-main .section2-inner h2 {
    font-size: 48px;
    font-family: "Exo 2", sans-serif;
    font-weight: 500;
    line-height: 56px;

    margin-bottom: 25px;
}

.aiSearch-main .section2-inner p {
    font-size: 24px;
    font-family: "Inter", sans-serif;
    text-align: justify;
}

.aiSearch-main .section3 {
    margin-top: 40px;
    margin-bottom: 40px;
    color: white;
}

.aiSearch-main .section3-heading h2 {
    font-size: 48px;
    font-weight: 500;
    font-family: "Exo 2", sans-serif;
}

.aiSearch-main.section3-inner_heading {
    margin-top: 10px;
}

.aiSearch-main .section3-inner_heading p {
    font-size: 32px;

    font-family: "Exo 2", sans-serif;
}

.aiSearch-main .section3-inner {
    margin-top: 22px;
}

.aiSearch-main .section3-inner_info p {
    font-size: 24px;
    font-family: "Inter", sans-serif;
    text-align: justify;
}

.aiSearch-main .section3-img_box {
    border-radius: 10px;
    overflow: hidden;
}

.aiSearch-main .section3-img_box img {
    width: 100%;
    height: 270px;
    object-fit: cover;
}

.aiSearch-main .section4 {
    margin-top: 40px;
    margin-bottom: 40px;
}

.aiSearch-main .section4-inner {
    color: white;
    padding: 30px 0;
}

.main-heading {
    font-size: 72px !important;
    line-height: 80px !important;
    margin-bottom: 28px;
}

.aiSearch-main .section4-inner-heading p {
    font-size: 48px;
    font-family: "Exo 2", sans-serif;
    line-height: 56px;
}

.aiSearch-main .section4-inner-heading2 {
    font-size: 48px;
    font-family: "Exo 2", sans-serif;
    line-height: 56px;
}

.aiSearch-main .section4-inner-info p {
    font-size: 24px;
    font-family: "Inter", sans-serif;
    line-height: 36px;
    text-align: justify;
    -webkit-font-smoothing: antialiased;
}

.aiSearch-main .section4-inner-info ol li {
    font-size: 24px;
    font-family: "Inter", sans-serif;
}


.aiSearch-main .section4-inner-button a {
    width: 150px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    border: none;
    border-radius: 7px;
    font-size: 16px;
    font-family: "Inter", sans-serif;
    text-decoration: none;
    color: #000;
}

.aiSearch-main .section4-img_box {
    /* border-radius: 10px; */
    /* overflow: hidden; */
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.aiSearch-main .section4-img_box img {
    width: 100%;
    height: 400px;
    object-fit: cover;
    border-radius: 10px;

    overflow: hidden;
}

.aiSearch-main .section5 {
    margin-top: 40px;
    margin-bottom: 40px;
    color: white;
}

.aiSearch-main .section5-heading p {
    font-size: 48px;
    font-family: "Exo 2", sans-serif;
    line-height: 56px;
}

.aiSearch-main .section-5-inner_heading {
    margin-top: 20px;
}

.aiSearch-main .section-5-inner_heading p {
    font-size: 32px;
    font-family: "Exo 2", sans-serif;
}

.aiSearch-main .secion-5-inner_info p {
    font-size: 24px;
    font-family: "Inter", sans-serif;
    text-align: justify;
}

.aiSearch-main .section-5-img_box {
    border-radius: 7px;
    overflow: hidden;
}

.aiSearch-main .section-5-img_box img {
    width: 100%;
    height: 264px;
    object-fit: cover;
}

.aiSearch-main .section6 {
    color: white;
    margin-top: 40px;
    margin-bottom: 40px;
}

.aiSearch-main .section6-inner_heading p {
    font-size: 48px;
    font-family: "Exo 2", sans-serif;
    line-height: 56px;
}

.aiSearch-main .section6-inner_info p {
    font-size: 24px;
    font-family: "Inter", sans-serif;
    text-align: justify;
}

.section7-inner-info p:first-child {
    font-weight: 600;
}

.aiSearch-main .section6-img_box img {
    width: 100%;
    object-fit: cover;
}

.aiSearch-main .section6-inner {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 80px;
}

.aiSearch-main .section7 {
    color: white;
    margin-bottom: 40px;
}

.aiSearch-main .section7-heading {
    text-align: center;
}

.aiSearch-main .section7-heading p {
    font-size: 48px;
    font-family: "Exo 2", sans-serif;
    line-height: 56px;
}

.aiSearch-main .section7-inner-heading {
    margin-top: 10px;
}

.aiSearch-main .section7-inner-heading p {
    text-align: center;
    font-size: 32px;
    font-family: "Exo 2", sans-serif;
}

.aiSearch-main .section7-inner-info p {
    font-size: 18px;
    font-family: "Inter", sans-serif;
}

.aiSearch-main .section7-inner-img_box img {
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
}


.aiSearch-main .banner2-inner {
    text-align: center;
    /* padding: 0px 120px; */
}

.aiSearch-main .banner2 {
    background-image: url('../../src//assets//banner2.jpeg');
    background-repeat: no-repeat;
    height: 550px;
    background-position: center;
    position: relative;
    z-index: 1;

}

.aiSearch-main .banner2-outer::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(0, 0, 83);
    opacity: 0.4;
    z-index: -1;
}

.aiSearch-main .banner2-inner-heading p {
    font-size: 72px;
    line-height: 80px;
    color: white;
    font-family: "Exo 2", sans-serif;
}

.aiSearch-main .banner2-inner-info p {
    font-size: 24px;
    font-family: "Inter", sans-serif;
    color: white;
}

.aiSearch-main .banner2-outer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.aiSearch-main .section9 {
    background-image: url("../assets//banner3.png");
    background-repeat: no-repeat;
    background-size: cover;
    padding: 40px 0;
    position: relative;
    z-index: 1;
}

.aiSearch-main .section9-heading {
    text-align: center;
}

.aiSearch-main .section9-heading p {
    font-size: 48px;
    color: white;
    font-family: "Exo 2", sans-serif;
    line-height: 56px;
}


.aiSearch-main .accordion,
.accordion-item,
.accordion-button,
.accordion-body,
.accordion-header {
    background-color: transparent !important;
}

.aiSearch-main .accordion-item {
    border: none !important;
    border-width: 0px 0px 1px;
    box-shadow: rgba(255, 255, 255, 0.2) 0px -1px 0px 0px inset;

}

.aiSearch-main .section9-inner :is(.accordion-header, .accordion-button) {
    border: 0px !important;
    color: #fff;
    text-align: center;
    font-size: 32px;
    justify-content: center;
    font-weight: 500;
}

.accordion-item:last-child .accordion-header {
    border-bottom: 0px !important;
}

.aiSearch-main .section9::after {
    background-color: #000053;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    content: "";
    position: absolute;
    z-index: -1;
    opacity: 0.5;
}

.aiSearch-main .accordion-button {
    padding: 50px 20px 50px;
    box-shadow: none !important;
    font-family: "Exo 2", sans-serif;
}

.aiSearch-main .accordion-body {
    color: #fff !important;
    text-align: center;
    padding: 0px 50px 50px;
    font-size: 18px;
    font-family: "Inter", sans-serif;
}

.aiSearch-main .accordion-button::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27none%27 stroke=%27%23ffffff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27%3e%3cpath d=%27M2 5L8 11L14 5%27/%3e%3c/svg%3e") !important;
    margin: 0px;
    right: 0;
    position: absolute;
}


@media screen and (max-width:1400px) {

    .aiSearch-main .banner-inner {
        width: 75vw;
    }

    .aiSearch-main .banner-inner h1 {
        font-size: 52px;
    }

    .aiSearch-main .banner-inner p {
        font-size: 20px;
    }

    .aiSearch-main .section2-inner h2,
    .aiSearch-main .section3-heading h2,
    .aiSearch-main .section4-inner-heading2,
    .aiSearch-main .section5-heading p,
    .contact-us .right-section .right-section-heading p,
    .aiSearch-main .section7-heading p {
        font-size: 36px;
        font-weight: 700;
    }

    .aiSearch-main .section2-inner p {
        font-size: 20px;
    }


    .aiSearch-main .section3-inner {
        margin-top: 35px;
    }

    .aiSearch-main .section3-inner_heading p {
        font-size: 24px;
        font-weight: 700;
        margin-top: 10px;
    }

    .aiSearch-main .section3-inner_info p {
        font-size: 18px;
    }

    .aiSearch-main .section4-inner-heading p {
        font-size: 36px;
        font-weight: 700;
        line-height: 40px;
    }


    .aiSearch-main .section4-inner-info p {
        font-size: 18px;
    }

    .aiSearch-main .section4-inner-info ol li {
        font-size: 18px;
    }

    .aiSearch-main .section-5-inner_heading p {
        font-size: 24px;
        font-weight: 700;
        line-height: 30px;
    }

    .aiSearch-main .secion-5-inner_info p {
        font-size: 18px;
    }

    .contact-us .right-section .right-section-info p {
        font-size: 18px;
    }

    .aiSearch-main .section6-inner_heading p {
        font-size: 36px;
        font-weight: 700;
        line-height: 40px;
    }

    .aiSearch-main .section6-inner_info p {
        font-size: 18px;
    }

    .aiSearch-main .section7-inner-heading p {
        font-size: 24px;
        font-weight: 700;
    }

    .aiSearch-main .banner2-inner {
        padding: 0px 10px;
    }

    .aiSearch-main .banner2-inner-heading p {
        font-size: 40px;
        font-weight: 700;
        line-height: 42px;
    }

    .aiSearch-main .banner2-inner-info p {
        font-size: 20px;
    }

    .aiSearch-main .section9-heading p {
        font-size: 36px !important;
        font-weight: 700;
    }

    .contact-us .left-section-three {
        flex-direction: column-reverse;
        gap: 10px;
    }

    .contact-us .left-section-three p {
        margin-bottom: 20px !important;
        width: 100% !important;
        font-size: 12px !important;
    }

    .main-heading {
        font-size: 42px !important;
        line-height: 40px !important;
        margin-bottom: 28px;
    }
}


@media screen and (max-width:576px) {
    .aiSearch-main img {
        height: 225px !important;
    }

    .aiSearch-main .banner-inner {
        width: 100vw;
        padding: 0px 15px;
    }

    .aiSearch-main .section2-inner h2,
    .aiSearch-main .section3-heading h2 {
        font-size: 30px;
    }

    .aiSearch-main .banner-inner h1 {
        font-size: 42px;
    }

    .aiSearch-main .banner2-inner {
        padding: 0px 20px;
    }

    :is(.section3-inner-heading, .section4-inner-heading, ) {
        margin-top: 10px;
    }

    :is(.section3-inner-heading, .section4-inner-heading, ) p {
        font-size: 24px;
        font-weight: 700;
    }

    .aiSearch-main .section4-inner-info ol li {
        font-size: 18px;
    }

    .aiSearch-main .banner2-inner-heading p {
        font-size: 32px !important;
        font-weight: 700;
        line-height: 36px;
    }

    .aiSearch-main .section6-inner {
        padding: 0 20px;
        margin-top: 20px;
    }

    .aiSearch-main .section9-inner :is(.accordion-header, .accordion-button) {
        font-size: 24px;
    }

    .aiSearch-main .section3-inner_heading p,
    .aiSearch-main .section4-inner-heading p,
    .section4-inner-heading2 p,
    .aiSearch-main .section-5-inner_heading p,
    .contact-us .right-section .right-section-heading p {
        font-size: 20px !important;
    }

    .contact-us .left-section-three {
        flex-direction: column-reverse;
        gap: 10px;
    }

    .contact-us .left-section-three p {
        margin-bottom: 20px !important;
        width: 100% !important;
        font-size: 12px !important;
    }

    .aiSearch-main .section6 {
        color: white;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .main-heading {
        margin-bottom: 0px;
    }

}

.error-message{
    color: red;
}
