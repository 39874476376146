.contact-us3 {
    padding: 100px 0;
    position: relative;
    z-index: 1;
}

.contact-us3::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: rgb(35 35 99);
    opacity: 0.88;
    z-index: -1;
    top: 0;
    left: 0;
}

.contact-us3 .right-section {
    padding: 0 60px;
}

.contact-us3 .right-section .right-section-heading p {
    font-size: 48px;
    color: white;
    font-family: "Exo 2", sans-serif;
}

.contact-us3 .right-section .right-section-info p {
    font-size: 24px;
    color: white;
    font-family: "Inter", sans-serif;
    text-align: justify;
}

.contact-us3 .left-section form input {
    background-color: #ffffff80 !important;
    border-radius: 8px;
    color: #ffffff;
    border: none !important;
    outline: none !important;
}
.contact-us3 .left-section form select {
    color: #ffffff;
}

.contact-us3 .left-section form textarea {
    background-color: #ffffff80 !important;
    border-radius: 8px;
    color: #ffffff;
    border: none !important;
    outline: none !important;
}

.contact-us3 .left-section-three {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.contact-us3 .left-section-three p {
    margin-bottom: 0px;
    color: #fff;
    width: 50%;
    font-family: "Inter", sans-serif;
}

.contact-us3 .left-section-three a {
    font-weight: 500;
    color: #fff;
    text-decoration: none;
    font-family: "Inter", sans-serif;
}

.contact-us3 .left-section-three button {
    width: 144px;
    height: 40px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    font-size: 16px;
    font-family: "Inter", sans-serif;
}

@media screen and (max-width:1400px) {
    .contact-us3 .right-section .right-section-heading p {
        font-size: 36px;
        font-weight: 700;
    }

    .contact-us3 .right-section .right-section-info p,
    .contact-us3 .right-section .right-section-info ul li {
        font-size: 18px;
    }

    .contact-us3 .left-section-three {
        flex-direction: column-reverse;
        gap: 10px;
    }

    .contact-us3 .left-section-three p {
        margin-bottom: 20px !important;
        width: 100% !important;
        font-size: 12px !important;
    }

    .contact-us3 {
        padding: 50px 0px;
    }

    .contact-us3 .right-section {
        padding: 0px;
    }
}


@media screen and (max-width:576px) {
    .contact-us3 .right-section .right-section-heading p {
        font-size: 20px !important;
    }

    .contact-us3 .left-section-three {
        flex-direction: column-reverse;
        gap: 10px;
    }

    .contact-us3 .left-section-three p {
        margin-bottom: 20px !important;
        width: 100% !important;
        font-size: 12px !important;
    }

    .contact-us3 .right-section {
        padding-left: 0px;
    }

    .contact-us3 .right-section .right-section-info ul li {
        font-size: 18px;
    }

    .contact-us3 {
        padding: 30px 0px;
    }

}

.error-message{
    color: red;
}