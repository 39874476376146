.contact-us4 {
    background-image: url('../../../src/assets/banner10.jpeg');
    background-size: cover;
    background-position: center;
    z-index: 1;
    position: relative;
    padding: 50px 0;
}

.contact-us4::after {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    content: "";
    background-color: #000053;
    opacity: 0.66;
    z-index: -1;
}


.contact-us4 .right-section .right-section-heading h2 {
    color: white;
    font-size: 32px;
    font-weight: 600;
    font-family: "Exo 2", sans-serif;
}

.contact-us4 .right-section .right-section-bottom {
    padding-top: 40px;
}

.contact-us4 .right-section .right-section-bottom .vt-block {
    padding: 20px 0;
}

.contact-us4 .right-section .right-section-info {
    padding: 20px 0;
}

.contact-us4 .right-section .right-section-info p {
    color: white;
    font-size: 24px;
    font-family: "Inter", sans-serif;
}

.contact-us4 .right-section .right-section-bottom .vt-block p {
    color: white;
    font-size: 24px;
    font-family: "Inter", sans-serif;
}

.contact-us4 .right-section .right-section-bottom .vt-block a {
    color: white;
    font-size: 24px;
    font-family: "Inter", sans-serif;
}

.contact-us4 .left-section-three button {
    width: 144px;
    height: 40px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    font-size: 16px;
    font-family: "Inter", sans-serif;
}

.contact-us4 .left-section-three {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.contact-us4 .left-section-three p {
    margin-bottom: 0px;
    color: #fff;
    width: 50%;
    font-family: "Inter", sans-serif;
}

.contact-us4 .left-section-three a {
    font-weight: 500;
    color: #fff;
    text-decoration: none;
    font-family: "Inter", sans-serif;
}

.contact-us4 .left-section form input {
    background-color: #ffffff80 !important;
    border-radius: 8px;
    color: #ffffff;
    border: none !important;
    outline: none !important;
}

.contact-us4 .left-section form textarea {
    background-color: #ffffff80 !important;
    border-radius: 8px;
    color: #ffffff;
    border: none !important;
    outline: none !important;
}

.form-block label {
    font-size: 16px;
    font-family: "Inter", sans-serif;
    color: #fff;
    margin-bottom: 4px;
}

.form-heading-title {
    font-size: 17px;
    font-weight: 600;
    font-family: "Inter", sans-serif;
    color: #fff;
}

@media screen and (max-width:1400px) {
    .contact-us4 .right-section .right-section-heading p {
        font-size: 36px;
        font-weight: 700;
    }

    .contact-us4 .right-section .right-section-info p {
        font-size: 18px;
    }

    .contact-us4 .left-section-three {
        flex-direction: column-reverse;
        gap: 10px;
    }

    .contact-us4 .left-section-three p {
        margin-bottom: 20px !important;
        width: 100% !important;
        font-size: 12px !important;
    }
}


@media screen and (max-width:576px) {
    .contact-us4 .right-section .right-section-heading p {
        font-size: 20px !important;
    }

    .contact-us4 .left-section-three {
        flex-direction: column-reverse;
        gap: 10px;
    }

    .contact-us4 .left-section-three p {
        margin-bottom: 20px !important;
        width: 100% !important;
        font-size: 12px !important;
    }

}


.error-message{
    color: red;
}
