body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;


  font-family: 'Inter', ui-sans-serif, system-ui, sans-serif;
  overflow-x: hidden;
  background-color: #130051;

}



code {
  font-family: "source-code-pro", "Menlo, Monaco", "Consolas", 'Courier New',
    monospace;
}

/* error css start */

section.hero-section {
  width: 100%;
  height: 100vh;
  background: #dfecff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
section.hero-section::before {
  width: 1500px;
  height: 1500px;
  background: linear-gradient(195deg, #009ffd, #2a2a72);
  position: absolute;
  content: "";
  top: 50%;
  right: -45%;
  transform: translateY(-50%) skew(324deg, 10deg);
  z-index: -1;
}
.payment-wrap-blk {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
}

.payment-content-blk {
  padding-right: 100px;
  width: 40%;
}
.payment-img-blk {
  width: 60%;
}
.payment-img-blk img {
  width: 100%;
  height: 100%;
}

.payment-content-blk h1 {
  font-size: 54px;
  font-weight: 700;
  margin-bottom: 40px;
  color: #253d6c;
}
.payment-content-blk h1 span {
  color: #f2767f;
  font-size: 90px;
  margin-left: 10px;
}
.payment-content-blk p {
  font-size: 20px;
  color: #000;
  font-weight: 400;
}
.payment-success-blk h1 span {
  color: #e8505b;
}
.payment-success-blk p {
  font-size: 18px;
  color: #938e8e;
  margin-bottom: 0px;
}
.payment-page-btn-blk a {
  background: #0047ab;
  padding: 10px 20px;
  border-radius: 30px;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  color: #fff;
  display: inline-block;
}
.payment-page-btn-blk {
  margin-top: 40px;
}

@media screen and (min-width: 1000px) and (max-width:1400px) {
  section.hero-section::before {
      right: -85%;
  }
  .payment-content-blk {
      padding-right: 0px;
  }
}
@media screen and (max-width: 991px) {
  .payment-wrap-blk {
      justify-content: center;
      align-items: center;
      flex-direction: column-reverse;
  }
  .payment-content-blk {
      padding-right: 0px;
      width: 100%;
      text-align: center;
  }
  .payment-img-blk {
      width: 70%;
  }
  section.hero-section::before {
      display: none;
  }
}
@media screen and (max-width:576px) {
  .payment-success-blk {
      padding: 20px;
      margin-top: 20px;
      margin-bottom: 20px;
  }
  .payment-content-blk h1 {
      font-size: 36px;
      margin-bottom: 20px;
  }
  .payment-content-blk p {
      font-size: 16px;
  }
  .payment-img-blk {
      width: 90%;
  }
}

/* error css end */



@media screen and (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

@media screen and (max-width:992px) {
  .complany-logo {
    width: 200px;
  }
}

@media screen and (max-width:768px) {
  .complany-logo {
    width: 200px;
  }
}

@media screen and (max-width:576px) {
  .complany-logo {
    width: 140px;
  }
}