.sell-more .banner {
    background-image: url('../../src/assets/banner8.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    z-index: 1;
    padding: 200px 0;
}

.sell-more .banner::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    background-color: #000053;
    opacity: 0.22;
    z-index: -1;
}

.sell-more .banner .inner .heading h2 {
    font-size: 72px;
    color: white;
    font-family: "Exo 2", sans-serif;
}

.sell-more .banner .inner .content p {
    font-size: 24px;
    color: white;
    font-family: "Inter", sans-serif;
}

.sell-more .banner .inner .button a {
    width: 190px;
    height: 60px;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    font-family: "Inter", sans-serif;
    border-radius: 7px;
    font-weight: 500;
    color: #000;
    background-color: #fff;
    text-decoration: none;
}

.sell-more .section2 {
    padding: 50px 0;
}

.sell-more .section2 .content {
    padding: 20px 0;
}

.sell-more .section2 .heading h2 {
    font-size: 48px;
    color: white;
    font-family: "Exo 2", sans-serif;
}

.sell-more .section2 .content ol li {
    color: white;
    font-size: 24px;
    font-family: "Inter", sans-serif;
}

.sell-more .section2 .content ol li h2 {
    font-size: 24px;
    color: white;
    font-family: "Inter", sans-serif;
}

.sell-more .section2 .content ol li p {
    font-size: 24px;
    color: white;
    font-family: "Inter", sans-serif;
}

.sell-more .section3 .heading {
    text-align: center;
    padding-top: 10px;
}

.sell-more .section3 .heading h2 {
    font-size: 48px;
    color: white;
    font-family: "Exo 2", sans-serif;
}

.sell-more .section3 {
    padding: 20px 0;
}

.sell-more .section3 .vt-card {
    padding: 20px 0;
}

.sell-more .section3 .vt-card .img-box img {
    width: 100%;
    object-fit: cover;
    height: 264px;
}

.sell-more .section3 .vt-card .heading h2 {
    font-size: 32px;
    color: white;
    font-family: "Exo 2", sans-serif;
}

.sell-more .section3 .vt-card .sub-heading {
    text-align: center;
    padding: 20px 0;
}

.sell-more .section3 .vt-card .sub-heading h2 {
    font-size: 24px;
    color: white;
    font-family: "Inter", sans-serif;
}

.sell-more .section3 .vt-card .content p {
    font-size: 24px;
    color: white;
    font-family: "Inter", sans-serif;
}

@media screen and (max-width:1400px) {
    .sell-more .banner .inner .heading h2 {
        font-size: 52px;
    }

    .sell-more .banner .inner .content p {
        font-size: 20px;
    }

    .sell-more .section2 .heading h2,
    .sell-more .section3 .heading h2 {
        font-size: 36px;
    }

    .sell-more .section2 .content ol li p,
    .sell-more .section3 .vt-card .content p {
        font-size: 18px;
    }
}

@media screen and (max-width:576px) {
    .sell-more .banner .inner .heading h2 {
        font-size: 42px;
    }

    .sell-more .banner .inner .content p,
    .sell-more .section3 .vt-card .content p {
        font-size: 18px;
    }

    .sell-more .section2 .heading h2,
    .sell-more .section3 .heading h2,
    .sell-more .section3 .vt-card .heading h2 {
        font-size: 30px;
    }

    .sell-more .section3 .vt-card .sub-heading h2 {
        font-size: 20px;
    }
}