.industry-world .banner .banner-inner .heading p {
    font-size: 72px;
    font-family: "Exo 2", sans-serif;
    color: white;
    line-height: 72px;
    font-weight: 600;
}

.industry-world .banner .banner-inner .content p {
    font-size: 24px;
    font-family: "Inter", sans-serif;
    color: white;
}

.industry-world .banner {
    background-image: url("../../src/assets/banner6.jpeg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    z-index: 1;
    padding: 50px 0;
}

.industry-world .banner::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    background-color: #000053;
    opacity: 0.5;
    z-index: -1;
}

.industry-world .banner .banner-inner .button a {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    width: 160px;
    height: 56px;
    border: none;
    border-radius: 7px;
    font-family: "Inter", sans-serif;
    text-decoration: none;
    color: #000;
    background-color: white;
}


.industry-world .section2 {
    padding: 50px 0;
}

.industry-world .section2 .heading p {
    font-size: 48px;
    color: white;
    font-family: "Exo 2", sans-serif;
}

.industry-world .section2 .content .content-inner :is(h4, p) {
    color: white;
    font-family: "Inter", sans-serif;
    padding: 10px 0;
}

.industry-world .section2 .content .content-inner p {
    font-size: 24px;
}

.industry-world .section3 .heading {
    text-align: center;
}

.industry-world .section3 .heading p {
    font-size: 48px;
    color: white;
    font-family: "Exo 2", sans-serif;
}

.industry-world .section3 .vt-card .heading p {
    font-size: 32px;
    color: white;
    font-family: "Exo 2", sans-serif;
}

.industry-world .section3 {
    padding: 50px 0;
}

.industry-world .section3 .vt-card .img-box {
    border-radius: 7px;
    overflow: hidden;
}

.industry-world .section3 .vt-card .img-box img {
    width: 100%;
    object-fit: cover;
    height: 270px;
}

.industry-world .section3 .vt-card .content p {
    font-size: 24px;
    color: white;
    font-family: "Inter", sans-serif;
}

.industry-world .section4 .heading p {
    font-size: 48px;
    color: white;
    font-family: "Exo 2", sans-serif;
}

.industry-world .section4 .vt-card .heading p {
    font-size: 32px;
    color: white;
    font-family: "Exo 2", sans-serif;
}

.industry-world .section4 .vt-card .content p {
    font-size: 24px;
    color: white;
    font-family: "Inter", sans-serif;
}

.industry-world .section4 {
    padding: 50px 0;
    border-top: 5px solid #fff;
}

.industry-world .section4 .vt-card .img-box img {
    width: 100%;
    object-fit: cover;
    height: 270px;
}

.industry-world .section5 .inner .heading p {
    font-size: 48px;
    color: #111827;
    font-family: "Exo 2", sans-serif;
}

.industry-world .section5 .inner .content p {
    font-size: 24px;
    color: #111827;
    margin-bottom: 40px;
    font-family: "Inter", sans-serif;
    /* padding: 53px 10px; */
}

.industry-world .section5 .img-box img {
    width: 100%;
    height: 632px;
    object-fit: cover;
}

.industry-world .section5 .inner {
    background-color: #e2e4f7;
    padding: 20px 45px;
    height: 632px;
}

.industry-world .section6 {
    background-image: url("../../src/assets/banner5.jpeg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    z-index: 1;
}

.industry-world .section6::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    background-color: #000053;
    opacity: 0.7;
    z-index: -1;
}

.section6 {
    padding: 50px 0;
}

.section6 .heading {
    text-align: center;
}

.section6 .heading p {
    font-size: 48px;
    color: white;
    font-family: "Exo 2", sans-serif;
}

.section6 .accordion,
.accordion-item,
.accordion-button,
.accordion-body,
.accordion-header {
    background-color: transparent !important;
}

.section6 .accordion-item {
    border: none !important;
    border-width: 0px 0px 1px;
    box-shadow: rgba(255, 255, 255, 0.2) 0px -1px 0px 0px inset;

}

.section6 :is(.accordion-header, .accordion-button) {
    border: 0px !important;
    color: #fff;
    text-align: center;
    font-size: 32px;
    justify-content: center;
}


.section6 .accordion-button {
    padding: 50px 20px 50px;
    box-shadow: none !important;
    font-family: "Exo 2", sans-serif;
}

.section6 .accordion-body {
    color: #fff !important;
    text-align: center;
    padding: 0px 50px 50px;
    font-size: 18px;
    font-family: "Inter", sans-serif;
}

.section6 .accordion-button::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27none%27 stroke=%27%23ffffff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27%3e%3cpath d=%27M2 5L8 11L14 5%27/%3e%3c/svg%3e") !important;
    margin: 0px;
    right: 0;
    position: absolute;
}

@media screen and (max-width:1400px) {
    .industry-world .section2 .heading p {
        font-size: 36px;
        color: white;
        font-family: "Exo 2", sans-serif;
    }

    .industry-world .banner .banner-inner .heading p {
        font-size: 42px !important;
        line-height: 56px;
    }

    .industry-world .section3 .heading p,
    .industry-world .section4 .heading p,
    .industry-world .section5 .inner .heading p {
        font-size: 36px;
    }

    .industry-world .section3 .vt-card .heading p,
    .industry-world .section4 .vt-card .heading p {
        font-size: 24px;
    }

    .industry-world .section2 .content .content-inner p,
    .industry-world .section3 .vt-card .content p,
    .industry-world .section4 .vt-card .content p,
    .industry-world .section5 .inner .content p {
        font-size: 18px;
    }
}

@media screen and (max-width:768px) {

    .industry-world .banner .banner-inner .heading p {
        font-size: 32px !important;
        line-height: 40px;
    }

    .industry-world .section2 .content .content-inner p {
        font-size: 18px;
    }
}

@media screen and (max-width:576px) {
    .industry-world .banner {
        padding: 150px 0;
    }

    p {
        font-size: 18px !important;
    }

    .industry-world .section2 .heading p {
        font-size: 30px !important;
    }

    .heading p {
        font-size: 20px !important;
        font-weight: 700;
        margin-top: 10px;
    }

    .industry-world .banner .banner-inner .heading p {
        font-size: 24px !important;
        line-height: 30px;
    }

    .industry-world .banner .banner-inner .content p {
        font-size: 16px !important;
    }

    .industry-world .banner .banner-inner .button button {
        font-size: 16px;
        width: 132px;
        height: auto;
        padding: 10px 16px;
        border: 0px;
    }

    .industry-world .section2 .heading p {
        font-size: 24px;
        font-weight: 600;
    }

    .industry-world .section3 .vt-card .img-box img,
    .industry-world .section4 .vt-card .img-box img {
        height: 200px;
    }

    .industry-world .section4 .vt-card .img-box img {
        object-fit: contain;
    }

    .industry-world .section2,
    .industry-world .section3 {
        padding: 30px 0;
    }

    .industry-world .section5 .inner {
        padding: 20px 20px;
        height: auto;
    }

    .industry-world .section5 .img-box img {
        height: 200px;
    }

    .industry-world .section5 .inner .content p {
        font-size: 16px !important;
        margin-bottom: 16px;
    }

    .section6 :is(.accordion-header, .accordion-button) {
        font-size: 24px;
    }

    .section6 .heading p {
        font-size: 32px !important;
    }
}