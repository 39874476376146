.hidden-page .section1 {
    position: relative;
    background-image: url('../../src/assets/banner9.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 50px 0;
    z-index: 1;
}

.hidden-page .section1::after {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    content: "";
    background-color: #000053;
    opacity: 0.44;
    z-index: -1;
}

.hidden-page .section1 .heading h1 {
    color: white;
    font-size: 72px;
    font-weight: 600;
    font-family: "Exo 2", sans-serif;
}

.hidden-page .section1 .content p {
    color: white;
    font-size: 24px;
    font-family: "Inter", sans-serif;
}

.hidden-page .section1 .button button {
    color: black;
    font-size: 20px;
    font-family: "Inter", sans-serif;
    font-weight: 500;
    border: none;
    border-radius: 7px;
    padding: 10px 20px;
}

.hidden-page .section2 {
    padding: 100px 0;
}

.hidden-page .section2 .content p {
    color: white;
    font-size: 24px;
    font-family: "Inter", sans-serif;
    padding: 10px 0;
}

.hidden-page .section2 .content h2 {
    color: white;
    font-size: 48px;
    font-family: "Exo 2", sans-serif;
    font-weight: 500;
    padding: 10px 0;
}

.hidden-page .section3 .heading h2 {
    color: white;
    font-size: 48px;
    font-weight: 600;
    font-family: "Exo 2", sans-serif;
    padding-bottom: 20px;
}

.hidden-page .section3 .inner-content p {
    color: white;
    font-size: 24px;
    font-family: "Inter", sans-serif;
    padding: 10px 0;
}

.hidden-page .section4 {
    padding: 50px 0;
}

.hidden-page .section4 .content p {
    color: white;
    font-size: 24px;
    font-family: "Inter", sans-serif;
    padding: 10px 0;
}

.hidden-page .section4 .button button {
    color: black;
    font-size: 16px;
    font-family: "Inter", sans-serif;
    width: 120px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    border: none;
    border-radius: 7px;
}

.hidden-page .section4 .img-box img {
    width: 100%;
    object-fit: cover;
}

.hidden-page .section5 .heading h2 {
    color: white;
    font-size: 48px;
    font-weight: 600;
    font-family: "Exo 2", sans-serif;
    padding-bottom: 20px;
}

.hidden-page .section5 .content p {
    color: white;
    font-size: 24px;
    font-family: "Inter", sans-serif;
    padding: 10px 0;
    position: relative;
}

.hidden-page .section5 .content p::before {
    position: absolute;
    top: 10px;
    left: -36px;
    content: "->";
    width: 100%;
    height: 100%;

}

@media screen and (max-width:1400px) {
    .hidden-page .section1 .heading h1 {
        font-size: 52px;
    }

    .hidden-page .section1 .content p {
        font-size: 20px;
    }

    .hidden-page .section2 .content p,
    .hidden-page .section3 .inner-content p,
    .hidden-page .section4 .content p,
    .hidden-page .section5 .content p,
    .contact-us4 .right-section .right-section-bottom .vt-block p,
    .contact-us4 .right-section .right-section-bottom .vt-block a {
        font-size: 18px !important;
    }

    .hidden-page .section2 .content h2,
    .hidden-page .section3 .heading h2,
    .hidden-page .section5 .heading h2 {
        font-size: 36px;
    }
}

@media screen and (max-width:576px) {
    .hidden-page .section1 .heading h1 {
        font-size: 42px;
    }

    .hidden-page .section1 .content p {
        font-size: 18px;
    }

    .hidden-page .section2 .content h2,
    .hidden-page .section3 .heading h2,
    .hidden-page .section5 .heading h2 {
        font-size: 30px;
    }
}